<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <!-- <bookmarks /> -->
    </div>

    <!-- Right Col -->
    <b-navbar-nav class="nav align-items-center ml-auto">
      <locale />
      <!-- <dark-Toggler class="d-none d-lg-block" /> -->
      <!-- <search-bar /> -->
      <!-- <cart-dropdown /> -->
      <notification-dropdown v-if="activeUserInfo.uid"/>
      <b-button v-if="!activeUserInfo.uid" @click="$router.push('/login')">{{ $t('Login') }} </b-button>
      <user-dropdown v-if="activeUserInfo.uid"/>
    </b-navbar-nav>
  </div>
</template>

<script>
import moduleUserNotification from "@/store/userNotification/moduleUserNotification.js"
import { BButton, BLink, BNavbarNav } from 'bootstrap-vue'
import Bookmarks from './components/Bookmarks.vue'
import CartDropdown from './components/CartDropdown.vue'
import DarkToggler from './components/DarkToggler.vue'
import Locale from './components/Locale.vue'
import NotificationDropdown from './components/NotificationDropdown.vue'
import SearchBar from './components/SearchBar.vue'
import UserDropdown from './components/UserDropdown.vue'

export default {
  components: {
    BLink,
    BButton,
    // Navbar Components
    BNavbarNav,
    Bookmarks,
    Locale,
    DarkToggler,
    SearchBar,
    CartDropdown,
    NotificationDropdown,
    UserDropdown,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed:{
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
  },
created(){
  if (!moduleUserNotification.isRegistered) {
      this.$store.registerModule("notificationList", moduleUserNotification);
      moduleUserNotification.isRegistered = true;
   
    }
    if(this.activeUserInfo.uid){
      this.$store.dispatch("fetchNotifications")
}
}
}
</script>
