<template>
  <div style="position: relative;" class="p-0 m-0">
    <section style="background-color:#16538C" id="">
      <div class="text-center">
        <div class="">
          <div>
            <b-row>
              <b-col class="mt-1">
                <img src="/img/DawyiniWhithLogo.2d0f95e1.png" class="ImageClassFotter " />
                <br />
                <p style="text-align: center; font-weight:bold;margin-bottom: 0rem;font-size:15px;color:white">حمل تطبيق
                  داويني على الهاتف</p>
                <b-row class="mt-2 mx-5" style="margin-right:20%">
                  <div class="text-left">
                    <a class="m-1" target="_blank" href="https://apps.apple.com/gb/app/revise-it/id1499322270">
                      <img width="120px" src="@/assets/AppStore.png" />
                    </a>
                  </div>

                  <div class=" text-left ">
                    <a class="m-1" target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.ivalley.amedha2">

                      <img width="120px" src="@/assets/GooglePlay.png" />
                    </a>
                  </div>

                </b-row>
              </b-col>
              <div class="vl"></div>
              <b-col>
                <b-row>
                  <b-col class="mt-3 border-right" style="margin-right:15%">
                    <h3 id="hV2Right" style="color: white; text-decoration: underline;">{{ $t('PATIENTCAREFOOTER') }}
                    </h3>
                    <p v-for="(item, index) in careMenu" :key="index">
                      <a style="margin-top: 2px; color: white;" :href="$vs.rtl ? item.Arhref : item.href">{{
                        $t(item.name)
                      }}</a>
                    </p>
                  </b-col>
                  <b-col class=" mt-3 border-right">
                    <h3 style="color:white; text-decoration: underline;">{{ $t('COLLABORATEFotter') }} </h3>
                    <p v-for="(item, index) in collaborateMenu" :key="index">
                      <a style="margin-top: 2px; color:white" :href="item.href">{{
                        $t(item.name)
                      }}</a>

                    </p>
                  </b-col>
                </b-row>
                <div class="vl"></div>
              </b-col>
              <b-col>
                <br />
                <b-col>
                  <div style="">
                  </div>
                  <div class=" mt-2">
                  </div>
                  <h3 id="pV2Right" style="text-align:center; color: white; text-decoration: underline;"> {{
                    $t("FOLLOWUS") }}</h3>
                  <div class="ml-3">
                    <ul class="list-inline mb-0" style="text-align:center">
                      <li class="list-inline-item">
                      </li>
                      <li>
                        <div style="margin-top: 5px">
                          <a href="https://www.facebook.com/" target="_blank">
                            <img src="@/assets/images/social/facebook.png" class="ml-1 mr-1 socialImage" alt="Facebook" />
                          </a>
                          <a href="https://twitter.com/" target="_blank">
                            <img src="@/assets/images/social/twitter.png" class="socialImage" alt="https://twitter.com/"
                              href="https://twitter.com/" />
                          </a>
                          <a href=" https://www.linkedin.com/company//" target="_blank">
                            <img src="@/assets/images/social/linked.png" class="ml-1 mr-1 socialImage" alt="Linkedin" />
                          </a>
                          <a href="https://www.instagram.com/" target="_blank">
                            <img src="@/assets/images/social/instaIcon.png" class="ml-0 mr-1 socialImage" alt="Instagram"
                              style="max-width: 20px" />
                          </a>
                        </div>
                      </li>
                    </ul>
                    <br />
                    <br />
                    <b-row>
                      <b-col>
                        <p style="color:white" @click="goToPage(8)">Trust & Security</p>
                      </b-col>
                      <b-col>
                        <p style="color:white" @click="goToPage(7)">Privacy Policy</p>
                      </b-col>
                      <b-col>
                        <span style="color:white">Sitemap</span>
                      </b-col>
                    </b-row>
                  </div>
                </b-col>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <p style="color:white">All Rights Reserved Dawayini @ 2023</p>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import {
  BLink, BTab, BTabs, BAlert, BAvatar, BBadge, BButton
  , BCard, BCol, BDropdown, BDropdownItem
  , BFormInput, BMedia, BPagination,
  BRow, BTable, BTooltip, BImg
} from 'bootstrap-vue'

export default {
  components: {
    BTab, BTabs, BAlert, BAvatar, BBadge, BButton
    , BCard, BCol, BDropdown, BDropdownItem
    , BFormInput, BLink, BMedia, BPagination,
    BRow, BTable, BTooltip, BImg,
  },
  data() {
    return {
      careMenu: [
        {
          Arhref: "/pages/ContactUs",
          href: "/pages/ContactUs",
          name: "ContactUs",
        },
        {
          Arhref: "/Ar/FAQ",
          href: "/FAQ",
          name: "FAQ",
        },
        {
          href: "/PatientWaiver",
          Arhref: "/Ar/PatientWaiver",
          name: "PatientWaiver",
        },
      ],
      collaborateMenu: [
        {
          href: "/CollaborateDoctor",
          name: "Doctors",
        },
        // {
        //   href: "/CollaborateHospital",
        //   name: "Hospitals",
        // },
        {
          href: "/CollaborateStrategic",
          name: "Strategic",
        },
        //   {
        //    href:"/Collaborate",
        //    name:"Collaborators"
        //  },
        {
          href: "/CollaborateJoinOurTeam",
          name: "JoinOurTeam",
        },
        // {
        //   href:"/AboutUs",
        //   name:"AboutUs"
        // },
      ],
    }
  },
  methods: {
    goToPage(id) {
      if (id == 7) {
        this.$router.push({ name: "PrivacyPolicy", params: {} });
      }
      else if (id == 8) {
        this.$router.push({ name: "PrivacyConditions", params: {} });
      }
    },
  },

}
</script>
<style>
.ImageClassFotter {
  width: 190px;
  height: 100px;
  padding-top: 20px;
  color: white;
}
</style>
