<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ userData.displayName || userData.username }}
        </p>
        <span class="user-status">{{ userData.userRole }}</span>
      </div>
      <b-avatar
        size="40"
        :src="baseURL+activeUserImg"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!userData.displayName"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      :to="$acl.check('patient')? { name: 'LandingPage'} :$acl.check('doctor')?{ name: 'doctorLandingPage'}:{ name: 'hospitalLandingPage'}"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>{{ $t('Profile') }}</span>
    </b-dropdown-item>
    
    
   

    <b-dropdown-divider />

    <b-dropdown-item
    :to="$acl.check('patient')? { name: 'PatientSetting'} :$acl.check('doctor')?{ name: 'DoctorProfileData'}:{ name: 'UpdateHospitalProfile'}"

      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>{{ $t('Settings') }}</span>
    </b-dropdown-item>
   
    <b-dropdown-item
      link-class="d-flex align-items-center"
      @click="logout"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>{{ $t('Logout') }}</span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import { domain } from "@/gloabelConstant.js"
import { avatarText } from '@core/utils/filter'
import { BAvatar, BDropdownDivider, BDropdownItem, BNavItemDropdown } from 'bootstrap-vue'
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  data() {
    return {
      baseURL:domain,
      userData: {},
       userData: JSON.parse(localStorage.getItem('userData')),

      avatarText,
    }
  },
  computed:{
 
    activeUserImg() {
      debugger
      if(this.$store.state.AppActiveUser.Doctor&&this.$store.state.AppActiveUser.Doctor.ID>0)
         return this.$store.state.AppActiveUser.Doctor.ProfileImagePath;
      if(this.$store.state.AppActiveUser.Patient&&this.$store.state.AppActiveUser.Patient.ID>0)
         return this.$store.state.AppActiveUser.Patient.ProfileImagePath;
      if(this.$store.state.AppActiveUser.Hospital&&this.$store.state.AppActiveUser.Hospital.ID>0)
         return this.$store.state.AppActiveUser.Hospital.ImagePath;
    }
  },
  methods: {
    logout() {
debugger

this.$store.dispatch("auth/logout").then(()=>{
  debugger
if(this.$acl.check("patient")){

  this.$router.push("/").then(()=>{
           
    }).catch(() => {});
}
else{
  this.$router.push("/login").then(()=>{
    }).catch(() => {});

}
    

    this.$acl.change("anonymous")
    location.reload();

});


},
    // logout() {
    //   // Remove userData from localStorage
    //   // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
    //   localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
    //   localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)

    //   // Remove userData from localStorage
    //   localStorage.removeItem('userData')

    //   // Reset ability
    //   this.$ability.update(initialAbility)

    //   // Redirect to login page
    //   this.$router.push({ name: 'auth-login-v1' })
    // },
  },
}
</script>
