export default [
  {
    title: 'Dashboards',
    icon: 'HomeIcon',
    rule:['patient'],
    tagVariant: 'light-warning',
    children: [
      {
        title: 'Home',
        route: 'home',
        rule:['patient'],
      },
      {
        title: 'OnlineConsultations',
        route: 'AppointmentSearch',
        rule:['patient','anonymous']
      },     
      {
        title: 'HospitalInfo',
        route: 'HospitalInfo',
      },
      {
        title: 'SurgeryAndTreatment',
        route: 'SurgeryAndTreatment',
        rule:['patient','anonymous']

      },
    ],
  },
  {
    icon: 'HomeIcon',
    route: 'dashboard-ecommerce',
    rule:['anonymous'],
    title: 'Home',  
    tagVariant: 'light-warning',  
    
  },
  {
    icon: 'BriefcaseIcon',
    route: 'patientServices',
    rule:['patient','anonymous'],
    title: 'PatientServices',  
    tagVariant: 'light-warning',  
    
  },
  {
    icon: 'NavigationIcon',
    title: 'TravelArrangements',
    route: 'travelArrangments',
    rule:['patient','anonymous'],
    tagVariant: 'light-warning',
  },
  {
    title: 'OurPackages',
    route: 'OurPackages',
    icon: 'PackageIcon',
    rule:['patient','anonymous'],
    tagVariant: 'light-warning',
  },
]
