export default [
  {
    header: 'Dashboards',
    icon: 'HomeIcon',
    rule: ['patient'],
    children: [
      {
        title: 'Home',
        route: 'home',
        rule: ['patient'],
      },
      {
        title: 'OnlineConsultations',
        route: 'AppointmentSearch',
        rule: ['patient', 'anonymous']
      },
      {
        title: 'HospitalInfo',
        route: 'HospitalInfo',
      },
      {
        title: 'SurgeryAndTreatment',
        route: 'SurgeryAndTreatment',
        rule: ['patient', 'anonymous']

      },
    ],
  },
  {
    header: 'Home',
    icon: 'HomeIcon',
    route: 'home',
    rule: ['anonymous'],
    title: 'Home',

  },
  {
    header: 'PatientServices',
    icon: 'BriefcaseIcon',
    route: 'patientServices',
    rule: ['patient', 'anonymous'],
    title: 'PatientServices',
  },
  {
    title: 'OurDoctor',
    route: 'AppointmentSearchFiltter',
    header: 'OurDoctor',
    icon: 'PackageIcon',
    rule: ['patient', 'anonymous']
  },
  {
    header: 'ContactUs',
    icon: 'NavigationIcon',
    title: 'ContactUs',
    route: 'page-ContactUs',
    rule:['patient','anonymous']
  },
  // {
  //   title: 'OurPackages',
  //   route: 'OurPackages',
  //   header: 'OurPackages',
  //   icon: 'PackageIcon',
  //   rule:['patient','anonymous']
  // },
]
