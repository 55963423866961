/*=========================================================================================
  File Name: moduleCalendarActions.js
  Description: Calendar Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js";

export default {

  fetchNotifications({ commit }) {
    debugger;
    // return new Promise((resolve, reject) => {
      axios.get("/GetUserNotification")
        .then((response) => {
          commit('SET_Notifications', response.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    // })
  },
  
  SetAllNotificationAsNotNew({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/SetAllNotificationAsNotNew")
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  
  SetAllNotificationAsNotNew({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get("/SetAllNotificationAsNotNew")
        .then((response) => {
          
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  SetNotificationAsRead({ commit },notificationId) {
    return new Promise((resolve, reject) => {
      axios.get("/SetNotificationAsRead?notificationId="+notificationId)
        .then((response) => {
         
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  SetNotificationAsNotNew({ commit },notificationId) {
    return new Promise((resolve, reject) => {
      axios.get("/SetNotificationAsNotNew?notificationId="+notificationId)
        .then((response) => {
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
