<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notifications.filter((b)=>b.IsRead==false).length"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="23"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="light-primary"
        >
        {{ notifications.filter((b)=>b.IsRead==false).length }} New
        </b-badge>
      </div>
    </li>
    <!-- Notifications -->
    <!-- <vue-perfect-scrollbar
      v-once
      class="scrollable-container media-list scroll-area"
      tagname="li"
    > -->
    <div style="max-height:450px; overflow-y:scroll">
      <!-- Account Notification -->
      <!-- <b-link
        v-for="notification in notifications"
        :key="notification.index" 
      > -->
        <b-media class="p-1 m-2 round" v-for="notification in notifications"
        :key="notification.index"  :style="notification.IsRead == false? {backgroundColor:'#e8dfdf'}:{}" @click="openNotification(notification)">
          <!-- <template #aside>
           
          </template> -->
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.Title }}
            </span>
          </p>
          <small >{{ notification.Message }}</small>
        </b-media>
      <!-- </b-link> -->
      <!-- <b-link
        v-for="notification in notifications"
        :key="notification.index" :style="[notification.IsRead == false? {'backgroundColor':'#e8dfdf'}:'']" @click="openNotification(notification)"
      >
        <b-media>
          <p class="media-heading"
          :class="[`text-${notification.category}`]"
          >
          <div class="row"></div>
            <span class="font-weight-bolder col">
              {{ notification.Title }}
            </span>
            <small class="mt-1 whitespace-no-wrap col">{{ elapsedTime(new Date(notification.CreatedDate))}}</small>

          </p>
          
            <small class="notification-text">{{ notification.Message }} </small> 
        </b-media>

      </b-link> -->
      
      <!-- System Notification Toggler -->
      <!-- <div class="media d-flex align-items-center">
        <h6 class="font-weight-bolder mr-auto mb-0">
          System Notifications
        </h6>
        <b-form-checkbox
          :checked="true"
          switch
        />
      </div> -->

      <!-- System Notifications -->
      <!-- <b-link
        v-for="notification in systemNotifications"
        :key="notification.subtitle"
      >
        <b-media>
          <template #aside>
            <b-avatar
              size="32"
              :variant="notification.type"
            >
              <feather-icon :icon="notification.icon" />
            </b-avatar>
          </template>
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.title }}
            </span>
          </p>
          <small class="notification-text">{{ notification.subtitle }}</small>
        </b-media>
      </b-link> -->
    <!-- </vue-perfect-scrollbar> -->
  </div>
    <!-- Cart Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
    >Read all notifications</b-button>
    </li>
  </b-nav-item-dropdown>
</template>

<script>
import { BAvatar, BBadge, BButton, BFormCheckbox, BLink, BMedia, BNavItemDropdown } from 'bootstrap-vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Ripple from 'vue-ripple-directive';


export default {
  data(){
    return{
      perfectScrollbarSettings : {
      maxScrollbarLength: 60,
      wheelPropagation: false,
      // notifications:[],
    }
    }
  },
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  
  computed: {
     notifications()
    {
      return this.$store.state.notifications;
    },
    navbarColor() {
      let color = themeConfig.navbarColor;
      return color;
    },
    isThemedark() {
      return this.$store.state.theme;
    },
    navbarStyle() {
      let style = {};

      if (this.navbarType === "static") {
        style.transition = "all .25s ease-in-out";
      }

      return style;
    },
    navbarClasses() {
      return this.scrollY > 5 && this.navbarType === "static"
        ? null
        : "d-theme-dark-light-bg shadow-none";
    },
    scrollY() {
      return this.$store.state.scrollY;
    },

    // HELPER
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth;
    },
    windowWidth() {
      return this.$store.state.windowWidth;
    },

    // BOOKMARK & SEARCH
    data() {
      return this.$store.state.navbarSearchAndPinList;
    },
    starredPages() {
      return this.$store.state.starredPages;
    },
    starredPagesLimited: {
      get() {
        return this.starredPages.slice(0, 10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesLimited", list);
      }
    },
    starredPagesMore: {
      get() {
        return this.starredPages.slice(10);
      },
      set(list) {
        this.$store.dispatch("arrangeStarredPagesMore", list);
      }
    },

    // PROFILE
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    user_displayName() {
      return this.activeUserInfo?.displayName;
    },
    activeUserImg() {
      debugger
      if(this.$store.state.AppActiveUser.Doctor&&this.$store.state.AppActiveUser.Doctor.ID>0)
         return this.$store.state.AppActiveUser.Doctor.ProfileImagePath;
      if(this.$store.state.AppActiveUser.Patient&&this.$store.state.AppActiveUser.Patient.ID>0)
         return this.$store.state.AppActiveUser.Patient.ProfileImagePath;
      if(this.$store.state.AppActiveUser.Hospital&&this.$store.state.AppActiveUser.Hospital.ID>0)
         return this.$store.state.AppActiveUser.Hospital.ImagePath;
    }
  },
  methods: {
    gotoEditProfile(){
this.$router.push({

        name: "UpdateHospitalProfile",params:{Id:this.$store.state.AppActiveUser.Hospital.ID}

      });
    },
    checkNotificationAsRead(notificationId)
    {
      this.$store.dispatch("notificationList/SetNotificationAsRead",notificationId).then(()=>{
             this.$store.dispatch("fetchNotifications")
      });

    },
     openNotification(notification)
    {
      debugger
      this.checkNotificationAsRead(notification.ID);
      this.notifications.splice(0,1);


      if(notification.NotificationTypeID==22)
      {
             this.DoctorRate.DoctorID=notification.DoctorID;
           this.DoctorRate.PatientReserviedSessionID=notification.PatientReserviedSessionID;

             this.$emit('OpenDoctorRate');
      }
      else if(notification.NotificationTypeID==23)
      {
         this.DoctorRate.HospitalID = notification.HospitalID;
         this.DoctorRate.PatientReserviedSurgeryID = notification.PatientReseriedSurgeryID;

          this.$emit('OpenHospitalRate');
      }
      else{}
       // this.$router.push(notification.ReturnInfo);

    },
    GoToProfile()
    {
      if(this.$acl.check("patient"))
        this.$router.push('/patient/home').catch(() => {});
     else if(this.$acl.check("hospital"))
        this.$router.push('/hospital/Home').catch(() => {});
     else
        this.$router.push('/doctor/home').catch(() => {});

    },
    GoToLogin() {
      this.$router.push("/pages/login");
    },
    GoToHome() {
      this.$router.push("/");
    },
    selected(item) {
      this.$router.push(item.url).catch(() => {});
      this.showFullSearch = false;
    },
    actionClicked(item) {
      // e.stopPropogation();
      this.$store.dispatch("updateStarredPage", {
        index: item.index,
        val: !item.highlightAction
      });
    },
    showNavbarSearch() {
      this.showFullSearch = true;
    },
    showSearchbar() {
      this.showFullSearch = true;
    },
    elapsedTime(startTime) {
      let x = new Date(startTime);
      let now = new Date();
      var timeDiff = now - x;
      timeDiff /= 1000;

      var seconds = Math.round(timeDiff);
      timeDiff = Math.floor(timeDiff / 60);

      var minutes = Math.round(timeDiff % 60);
      timeDiff = Math.floor(timeDiff / 60);

      var hours = Math.round(timeDiff % 24);
      timeDiff = Math.floor(timeDiff / 24);

      var days = Math.round(timeDiff % 365);
      timeDiff = Math.floor(timeDiff / 365);

      var years = timeDiff;

      if (years > 0) {
        return years + (years > 1 ? " Years " : " Year ") + "ago";
      } else if (days > 0) {
        return days + (days > 1 ? " Days " : " Day ") + "ago";
      } else if (hours > 0) {
        return hours + (hours > 1 ? " Hrs " : " Hour ") + "ago";
      } else if (minutes > 0) {
        return minutes + (minutes > 1 ? " Mins " : " Min ") + "ago";
      } else if (seconds > 0) {
        return seconds + (seconds > 1 ? " sec ago" : "just now");
      }

      return "Just Now";
    },
    logout() {


      this.$store.dispatch("auth/logout");

      debugger
      if(this.$acl.check("patient"))
          this.$router.push("/").then(()=>{
                  location.reload();
          }).catch(() => {});
      else
          this.$router.push("/pages/login").then(()=>{
                  location.reload();
          }).catch(() => {});


          this.$acl.change("anonymous")
    },
    outside: function() {
      this.showBookmarkPagesDropdown = false;
    },

    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      let date = new Date();

      if (hr) {
        date.setHours(date.getHours() - hr);
      }
      if (min) {
        date.setMinutes(date.getMinutes() - min);
      }
      if (sec) {
        date.setSeconds(date.getSeconds() - sec);
      }

      return date;
    }
  },

  created() {
    // if (!moduleUserNotification.isRegistered) {
    //   this.$store.registerModule("notificationList", moduleUserNotification);
    //   moduleUserNotification.isRegistered = true;
   
    // }
    // this.$store.dispatch("fetchNotifications").then(res=>{
    //   debugger
    //   this.notifications = res.data
    // });
  },
  
}
</script>

<style>

</style>
